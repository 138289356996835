<template>
  <!--  TTB Notify  -->
  <div>
    <b-overlay :show="isTogging">
      <!--    -->
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            ดึงแจ้งเตือน Line
            <AutoBankingStatus :status="!!linkWalletLine" />
            <br>
            <small class="text-muted">(รองรับฝากเท่านั้น)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="linkWalletLine"
              id="bankingNotifyReceiverStatus"
              v-model="linkWalletLine.isEnabled"
              :disabled="isTogging"
              name="bankingNotifyReceiverStatus"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!linkWalletLine"
              @click="$bvModal.show('notify-line-modal')"
          />
          <AutoBankingLineNotifyModal :agent-bank-account-id="bankAccountForm.id" :link-wallet="linkWalletLine" type="ktb-connect" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            ดึงแจ้งเตือน App
            <AutoBankingStatus :status="!!bankingSmsReceiver" />
            <br>
            <small class="text-muted">(รองรับฝากเท่านั้น)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="bankingSmsReceiver"
              id="bankingNotifyReceiverStatus"
              v-model="bankingSmsReceiver.isEnabled"
              :disabled="isTogging"
              name="bankingNotifyReceiverStatus"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!bankingSmsReceiver"
              @click="$bvModal.show('notify-receiver-modal')"
          />
          <NotifyReceiverModal :agent-bank-account-id="bankAccountForm.id" :banking-sms-receiver="bankingSmsReceiver" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoTtbForm',
  components: {
    NotifyReceiverModal: () => import('../auto-banking-notify-modal'),
    AutoBankingLineNotifyModal: () => import('../auto-banking-line-notify-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        bankingSmsReceiver: null,
      })
    }
  },
  data() {
    return {
      bankingSmsReceiver: null,
      linkWallets: [],
      isTogging: false,
    }
  },
  computed: {
    linkWalletLine() {
      return this.linkWallets.find(({type}) => type === 'ktb-connect')
    },
  },
  watch: {
    'bankingSmsReceiver.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('sms')
      }
    },
    'linkWalletLine.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('link-wallet', 'ktb-connect')
      }
    },
  },
  created() {
    this.bankingSmsReceiver = this.bankAccountForm.bankingSmsReceiver
    this.linkWallets = this.bankAccountForm.linkWallets
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    async toggleStatus(banking, type) {
      this.isTogging = true
      await this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
        type
      })
      this.isTogging = false
    }
  }
})
</script>

<style scoped>

</style>